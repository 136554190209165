import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Alert from '../components/Alert';
import Input from '../components/forms/Input';
import Submit from '../components/forms/Submit';
import Icon from '../components/Icon';
import Layout from '../components/LayoutMinimal';
import { useAuth } from '../hooks/use-auth';
import { AuthContext, RequestToLoginUser } from '../interfaces';
import getServerSidePropsNonAuthGuard from '../utils/getServerSidePropsNonAuthGuard';
import validationSchema from '../validations/login';

export { getServerSidePropsNonAuthGuard as getServerSideProps };

type KeywordFields = {
  email: string;
  password: string;
  returnUrl?: string;
};

const useLogin = () => {
  const auth: AuthContext = useAuth();
  const router = useRouter();

  const params: string[][] =
    typeof window !== 'undefined'
      ? (window?.location?.search || '').split('&').map((param) => param.split('=', 2))
      : [[]];

  const returnUrl = decodeURIComponent(
    typeof window !== 'undefined' && (window?.location?.search || '').indexOf('returnUrl=') > -1
      ? params.filter((param) => param[0] === 'returnUrl')[0][1]
      : '/'
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<KeywordFields>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      returnUrl,
    },
  });

  const [keywordError, setKeywordError] = useState<string>();
  const [keywordLoading, setKeywordLoading] = useState<boolean>(false);

  useEffect(() => {
    if (auth?.user) {
      router.replace('/');
    }
  }, [auth?.user]);

  const onSubmit = handleSubmit(async (dataObject: RequestToLoginUser) => {
    if (!keywordLoading) {
      setKeywordError('');
      setKeywordLoading(true);

      try {
        await auth?.login(dataObject.email, dataObject.password, dataObject.returnUrl);
      } catch (err: any) {
        setKeywordError(err.toString().replace(/^Error: /, ''));
        setKeywordLoading(false);
      }
    }
  });

  return { onSubmit, errors, register, keywordError, keywordLoading };
};

const LoginPage: React.FC = () => {
  const { onSubmit, errors, register, keywordError, keywordLoading } = useLogin();

  const notAuthorized =
    typeof window !== 'undefined' ? (window?.location?.search || '').indexOf('not_authorized') > -1 : false;

  const welcome = notAuthorized ? 'Please login to continue' : 'Hi there, ready to Log in?';

  const dark = false;

  return (
    <Layout title="Login">
      <div className={`relative ${dark ? 'bg-gray-800' : 'bg-white'} p-6 md:p-12 rounded-lg shadow-md`}>
        <div className="relative w-16 h-16">
          <Link href={process.env.NEXT_PUBLIC_WEB_URL || ''}>
            <a>
              <Icon type="logo" />
            </a>
          </Link>
        </div>
        <div className="relative mb-10 text-left">
          <h1 className={`${dark ? 'text-gray-300' : 'text-gray-800'} font-medium m-0 mt-6 text-2xl`}>{welcome}</h1>
          <span className={`${dark ? 'text-gray-400' : 'text-gray-800'} text-sm font-medium`}>
            {' '}
            Don&apos;t have an account?{' '}
          </span>
          <Link href="/signup">
            <a className="text-sm">
              <span className="text-primary-lighter font-medium">Sign up</span>
            </a>
          </Link>
        </div>
        <div className="relative flex flex-col w-full min-w-0 break-words">
          <div className="flex-auto">
            <form onSubmit={onSubmit}>
              <input type="hidden" {...register('returnUrl')} />
              <Input
                type="email"
                name="email"
                label="Email Address: *"
                error={errors.email}
                register={register}
                dark={dark}
              />
              <Input
                type="password"
                name="password"
                label="Password: *"
                error={errors.password}
                register={register}
                dark={dark}
              />
              {keywordError && (
                <div className="my-6">
                  <Alert message={keywordError} color="red" canClose={false} />
                </div>
              )}
              <div className="mt-10">
                <Submit name="login" label="Log in" loading={keywordLoading} />
              </div>
            </form>
          </div>
        </div>
        <div className="relative mt-8 text-center">
          <Link href="/forgot">
            <a className="text-sm">
              <span className="text-primary-lighter font-medium">Forgot password?</span>
            </a>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
