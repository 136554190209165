type SubmitColors = 'secondary' | 'primary';

type Props = {
  label: string;
  loading: boolean;
  loadingIcon?: boolean;
  color?: SubmitColors;
  name?: string;
  inlineMode?: boolean;
};

const Submit: React.FC<Props> = ({
  label,
  loading,
  loadingIcon = true,
  color = 'secondary',
  name = undefined,
  inlineMode = false,
}: Props) => {
  return (
    <span className={`${inlineMode ? 'inline-block m-0' : 'block text-center mt-8'}`}>
      <button
        type="submit"
        disabled={loading}
        className={`${
          color === 'secondary'
            ? 'bg-secondary-lighter text-white active:bg-secondary-normal'
            : 'bg-primary-darker text-white active:bg-primary-dark'
        } mt-3 rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 xs:mt-0 xs:ml-3 xs:w-auto xs:text-sm ${
          inlineMode ? '' : 'mr-1 mb-1 w-full'
        } flex items-center`}
        style={{ transition: 'all .15s ease' }}
        name={name}
      >
        {/* border-transparent bg-primary-darker text-gray-50 hover:bg-primary-dark focus:ring-primary-normal */}

        {/* inline-flex justify-center  */}

        {loadingIcon &&
          (loading ? (
            <span className="rotate inline-block w-5 h-5 mr-5">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </span>
          ) : (
            <span className="inline-block w-5 h-5 mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          ))}
        {label}
      </button>
    </span>
  );
};

Submit.defaultProps = {
  loadingIcon: true,
  color: 'secondary',
  name: undefined,
  inlineMode: false,
};

export default Submit;
