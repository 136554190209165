import Head from 'next/head';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  title: string;
  dark?: boolean;
  inlineKeyword?: boolean;
};

const LayoutMinimal: React.FC<Props> = ({ children, title = 'Hello', dark = false, inlineKeyword = false }: Props) => (
  <>
    <Head>
      <title>{title}</title>
    </Head>
    <main>
      <section className="absolute w-full">
        <div
          className={`fixed top-0 left-0 w-full h-full ${
            // eslint-disable-next-line no-nested-ternary
            inlineKeyword ? 'bg-white' : dark ? 'bg-gray-900' : 'bg-gray-100'
          }`}
          style={
            dark
              ? {
                  backgroundImage: 'url(/images/minimal-bg.svg)',
                }
              : {}
          }
        />
        <div className="container h-full px-0 mx-auto">
          <div
            className={`${
              inlineKeyword
                ? ''
                : 'flex content-center items-start my-4 md:my-6 lg:my-6 xl:my-10 2xl:my-20 justify-center h-full'
            }`}
          >
            <div
              className={`w-full ${inlineKeyword ? 'p-0' : 'md:w-8/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12 px-4 md:px-10'} `}
            >
              {children}
            </div>
          </div>
        </div>
      </section>
    </main>
  </>
);

LayoutMinimal.defaultProps = {
  dark: false,
  inlineKeyword: undefined,
};

export default LayoutMinimal;
