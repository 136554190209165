import { FieldError } from 'react-hook-form';

export default function ResolveFormErrorMessage(error: FieldError): string {
  let errorMessage = '';

  if (error?.message) {
    errorMessage = error.message;
  } else if (error?.type === 'required') {
    errorMessage = 'This field is required';
  } else if (error) {
    errorMessage = error.type;
  }

  return errorMessage;
}
