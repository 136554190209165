import { useEffect, useState } from 'react';

type Props = {
  message: string | React.ReactElement;
  color: string;
  canClose: boolean;
  timer?: number;
  onTimeout?: () => void;
  paddingY?: number;
  animate?: 'top';
};

const Alert: React.FC<Props> = ({
  message,
  color,
  canClose,
  timer = 0,
  onTimeout = () => {},
  paddingY = 4,
  animate = undefined,
}: Props) => {
  const [showAlert, setShowAlert] = useState(true);
  const [secondsBeforeTimeout, setSecondsBeforeTimeout] = useState(timer);
  const [className, setClassName] = useState(animate === 'top' ? '-translate-y-20' : '');

  useEffect(() => {
    const id = setTimeout(() => {
      if (secondsBeforeTimeout > 0) {
        setSecondsBeforeTimeout((seconds) => seconds - 1);
      } else if (timer) {
        if (animate === 'top') {
          setClassName('-translate-y-20');
          setTimeout(() => {
            onTimeout();
          }, 400);
        } else {
          onTimeout();
        }
      }
    }, 1000);

    return () => clearTimeout(id);
  }, [secondsBeforeTimeout]);

  useEffect(() => {
    setTimeout(() => {
      setClassName('');
    }, 400);
  }, []);

  const closeAlert = () => {
    if (animate === 'top') {
      setClassName('-translate-y-20');

      setTimeout(() => {
        setShowAlert(false);
      }, 400);
    } else {
      setShowAlert(false);
    }
  };

  // bg-green-100 border-green-400 text-green-600 text-green-800
  // bg-red-100 border-red-400 text-red-600 text-red-800
  // py-4
  return (
    <>
      {showAlert ? (
        <div
          className={`bg-${color}-100 border-${color}-400 text-${color}-600 px-6 py-${paddingY} border border-dashed rounded mb-8 flex items-center justify-start ${
            animate === 'top'
              ? 'absolute w-full top-0 transition-all transform ease-in-out delay-0 duration-400'
              : 'relative'
          } ${className}`}
        >
          <span className="">
            <span className="inline-block w-5 h-5 mr-5 text-xl align-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className={`text-${color}-800`}
              >
                <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
              </svg>
            </span>
          </span>
          <span className={`inline-block max-w-full mr-8 align-middle text-${color}-800`}>
            {message}
            {timer ? <span className="inline-block ml-1">({secondsBeforeTimeout}...)</span> : null}
          </span>
          {canClose && (
            <button
              type="button"
              className="focus:outline-none absolute top-0 right-0 mt-4 mr-6 text-2xl font-semibold leading-none bg-transparent outline-none"
              onClick={() => closeAlert()}
            >
              <span>×</span>
            </button>
          )}
        </div>
      ) : null}
    </>
  );
};

Alert.defaultProps = {
  timer: 0,
  onTimeout: () => {},
  paddingY: 4,
  animate: undefined,
};

export default Alert;
