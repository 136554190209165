import { RefCallback } from 'react';
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form';
import ResolveFormErrorMessage from '../../utils/resolveFormErrorMessage';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  type: string;
  register?: unknown;
  registerCustom?: RefCallback<HTMLInputElement>;
  error?: FieldError;
  dark?: boolean;
  help?: string;
  list?: string;
};

const Input: React.FC<Props> = ({
  name,
  label,
  placeholder = undefined,
  type = 'text',
  register = undefined,
  registerCustom = undefined,
  error = undefined,
  dark = false,
  help = undefined,
  list = undefined,
}: Props) => {
  const domId = `input${name}`;

  return (
    <div className={`relative w-full ${error ? 'mb-2' : 'mb-6'}`}>
      <label className={`block ${dark ? 'text-gray-400' : 'text-gray-700'} text-sm font-medium mb-1`} htmlFor={domId}>
        {label}
      </label>
      <input
        className={`px-3 py-3 ${
          dark
            ? 'placeholder-gray-400 text-gray-100 bg-gray-700 focus:border-gray-500 focus:ring-gray-500 border-gray-600 border-2'
            : 'placeholder-gray-400 text-gray-700 bg-white focus:border-gray-300 border'
        } rounded text-sm shadow-sm focus:outline-none w-full ${error ? 'border-red-400' : 'border-gray-300'}`}
        style={{ transition: 'all .15s ease' }}
        id={domId}
        type={type}
        placeholder={placeholder}
        {...(register ? (register as UseFormRegister<FieldValues>)(name) : {})}
        {...(registerCustom ? { ref: registerCustom } : {})}
        list={list}
        autoComplete={list ? 'off' : undefined}
      />
      {error && (
        <span className="inline-block w-full pr-2 mt-1 text-xs text-right text-red-700">
          {ResolveFormErrorMessage(error)}
        </span>
      )}

      {help && <p className="pt-2 text-sm italic text-gray-500">{help}</p>}
    </div>
  );
};

Input.defaultProps = {
  dark: false,
  placeholder: undefined,
  help: undefined,
  register: undefined,
  registerCustom: undefined,
  error: undefined,
  list: undefined,
};

export default Input;
